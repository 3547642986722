<template>
  <page-view>
    <div class="child_header">
      <a-row :gutter="48" class="row">
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ paddingLeft: '0', paddingRight: '0' }"
        >
          <div class="filter_item">
            <span>工单编号</span>
            <a-input
              placeholder="工单编号"
              class="filter_input"
              v-model.trim="queryParam.no"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ paddingLeft: '0', paddingRight: '0' }"
        >
          <div class="filter_item">
            <span>工单类型</span>
            <a-select
              v-model="queryParam.typeName"
              class="filter_input"
              placeholder="请选择类型"
            >
              <a-select-option
                v-for="item in typeArrayAll"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </div>
        </a-col>

        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ paddingLeft: '0', paddingRight: '0' }"
        >
          <div class="filter_item">
            <span>查询时间</span>
            <a-range-picker
              class="filter_input"
              :value="[
                moment(queryParam.createTimeStart),
                moment(queryParam.createTimeEnd)
              ]"
              @change="onChangeDatePay"
            />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24">
          <div class="time_select">
            <span
              v-for="item in shortcutDate"
              :key="item.id"
              @click="handleShortcutDate(item)"
              :class="currentShortcutDate === item.date ? 'active' : ''"
              >{{ item.title }}</span
            >
          </div>
        </a-col>
        <a-col
          :lg="3"
          :md="12"
          :sm="24"
          :style="{ paddingLeft: '0', paddingRight: '0' }"
        >
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
              >查询</a-button
            >
            <a-button @click="resetQueryParam" class="query_btn">重置</a-button>
            <a-button :loading="batchCloseLoading" @click="batchCloseProblem"
              >批量结单</a-button
            >
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="invitetable_wrapper">
      <a-tabs @change="changeQueryType" :activeKey="activeKey">
        <a-tab-pane
          :key="item.value"
          :tab="item.name"
          v-for="item in problemStatusArr"
        ></a-tab-pane>
      </a-tabs>

      <s-table
        ref="table"
        rowKey="id"
        :columns="columns"
        :data="dataSource"
        :rowSelection="{
          selectedRowKeys: this.selectedRowKeys,
          selectedRows: this.selectedRows,
          onChange: (selectedRowKeys, selectedRows) => {
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRows = selectedRows;
          }
        }"
        style="margin:0 24px"
      >
        <span slot="serial" slot-scope="text, record, index">{{
          index + 1
        }}</span>

        <template slot="operation" slot-scope="text, record">
          <a style="margin-right:8px" @click="showDetail(record)">查看详情</a>
        </template>
      </s-table>
    </div>
    <a-modal
      title="工单详情"
      :width="860"
      :visible="showDetailData"
      @cancel="showTableList"
      :footer="null"
    >
      <div class="wrapper detail_wrapper top">
        状态：{{ problemStatusDetail[detail.status] }}
        <div>
          <a-button
            type="primary"
            @click="closeProblem2(detail.id, 'CLOSE')"
            style="margin-right:8px"
            v-if="
              detail.status == 'SELL' ||
                detail.status == 'BUY' ||
                detail.status == 'ADMIN'
            "
            >结单并回复</a-button
          >
          <a-button
            type="primary"
            @click="closeProblem(detail.id, 'CLOSE')"
            v-if="
              detail.status == 'SELL' ||
                detail.status == 'BUY' ||
                detail.status == 'ADMIN'
            "
            >结单</a-button
          >
          <a-button @click="showTableList" v-else>返回列表</a-button>
        </div>
      </div>
      <div class="wrapper detail_wrapper">
        <h3>工单信息</h3>
        <div class="desc_item">
          <span>工单编号 ：</span>
          <span>{{ detail.no }}</span>
        </div>
        <div class="desc_item">
          <span>工单标题 ：</span>
          <span>{{ detail.title }}</span>
        </div>
        <div class="desc_item">
          <span>问题类型 ：</span>
          <span>{{ detail.typeName }}</span>
        </div>
        <div class="desc_item">
          <span>创建时间 ：</span>
          <span>{{ formatDate(detail.createTime) }}</span>
        </div>
        <div class="desc_item">
          <span>{{ detail.typeId === 1 ? "微信号" : "联系人" }} ：</span>
          <span>{{ detail.username }}</span>
        </div>
        <div class="desc_item">
          <span>联系手机 ：</span>
          <span>{{ detail.mobile }}</span>
        </div>
      </div>
      <div class="wrapper concat_wrapper">
        <h3>解决记录</h3>
        <div class="concat_list">
          <div
            class="concat_item"
            v-for="(item, index) in detail.content"
            :key="index"
          >
            <div class="item_left" v-if="item.admin">
              <img
                src="@/assets/gd01.png"
                alt=""
                style="width:48px;height:48px;"
              />
            </div>
            <div class="item_left" v-else>
              <img
                src="@/assets/gd02.png"
                alt=""
                style="width:48px;height:48px;"
              />
            </div>
            <div class="item_center">
              <div class="item_center_name" v-if="item.admin">
                礼品网售后
              </div>
              <div class="item_center_name" v-else>{{ detail.username }}</div>
              <div class="item_center_info">{{ item.text }}</div>
              <img
                v-show="item.files"
                v-for="(item2, index2) in item.files"
                :key="index2"
                :src="item2"
                class="item_center_img"
                preview
              />
            </div>
            <div class="item_right">
              {{ formatDate(item.time) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="wrapper detail_wrapper"
        v-show="
          detail.status == 'SELL' ||
            detail.status == 'BUY' ||
            detail.status == 'ADMIN'
        "
      >
        <h3>
          继续沟通
          <span v-if="[1, 2].includes(detail.typeId)" style="font-weight: bold">
            【快递单号：
            <a @click="copyInfo(detail.waybillNo || '未填')">{{
              detail.waybillNo || "未填"
            }}</a>
            】
          </span>
        </h3>
        <div class="concat_input">
          <a-textarea
            style=" width: 860px; height: 140px"
            placeholder="输入沟通内容"
            :autoSize="{ minRows: 5 }"
            v-model="submit.input"
            class="resize_none"
          />
        </div>
        <div class="concat_upload">
          <div
            style="position:relative"
            @mouseenter="handleMouseEnter(item)"
            @mouseleave="handleMouseLeave(item)"
            v-show="submit.fileList"
            v-for="item in submit.fileList"
            :key="item.key"
          >
            <img
              title="点击预览"
              :src="item.url"
              class="submit_image"
              preview
            />
            <div
              class="edit_tip"
              v-show="item.show"
              @click="handleRemove(item)"
            >
              删除
            </div>
          </div>

          <!-- <a-upload
            :multiple="false"
            :showUploadList="false"
            listType="picture"
            :customRequest="handleChange"
            accept="image/gif, image/png, image/jpeg, image/webp"
          >
            <div v-show="submit.fileList.length < 5" class="picture_upload">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload> -->
        </div>

        <div @paste="handlePaste">
          <div class="paste_box" v-show="submit.fileList.length < 5">
            <span class="text">粘贴图片</span>
          </div>
          <p id="log">{{ pasteText }}</p>
          <div v-show="submit.fileList.length < 5">
            <div class="action_item">
              使用截图工具进行截图，再点击上方虚线框内粘贴区域，按下Ctrl+V即可，或者点击下方的上传图片，最多可上传5张图片。
            </div>
            <a-upload
              name="avatar"
              list-type="picture"
              class="avatar-uploader"
              :show-upload-list="false"
              :customRequest="handleChange"
              accept="image/gif, image/png, image/jpeg, image/webp"
            >
              <a-button style="margin-bottom:12px">点击上传</a-button>
            </a-upload>
          </div>
        </div>

        <div class="action_btn">
          <a-button class="submit_btn" type="primary" @click="handleSubmit"
            >提交</a-button
          >
          <a-button class="submit_btn" @click="showTableList"
            >返回列表</a-button
          >
        </div>
      </div>
    </a-modal>
  </page-view>
</template>

<script>
import { PageView } from "@/layouts";
import { STable } from "@/components";
import { filePreUpload, uploadOSS } from "@/api";
import { problemListAdmin, problemUpdateAdmin, problemClose } from "@/self";
import { formatDate, uuid, jsonToArray, problemStatus } from "@/utils/util";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
export default {
  components: {
    PageView,
    STable
  },
  data() {
    return {
      pasteText: "",
      time: null,
      showDetailData: false,
      detail: {},
      preview: { image: "", show: false },
      submit: {
        input: "",
        fileList: []
      },
      selectedRowKeys: [],
      selectedRows: [],
      batchCloseLoading: false,
      previewVisible: false,
      previewImage: "",
      concatListData: [],
      activeKey: "0",
      queryParam: {
        statusList: "BUY,ADMIN,SUCCESS,CANCEL,CLOSE",
        createTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        createTimeEnd:
          new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      },
      currentShortcutDate: 0,
      shortcutDate: [
        {
          id: 1,
          title: "今天",
          date: 0
        },
        {
          id: 2,
          title: "昨天",
          date: 1
        },
        {
          id: 3,
          title: "最近7天",
          date: 7
        },
        {
          id: 4,
          title: "最近30天",
          date: 30
        }
      ],
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" },
          width: 80
        },
        {
          title: "工单编号",
          dataIndex: "no"
        },
        {
          title: "标题",
          dataIndex: "title"
        },

        {
          title: "问题类型",
          dataIndex: "typeName"
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: text => this.problemStatusAll[text]
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          customRender: formatDate
        },
        {
          title: "修改时间",
          dataIndex: "updateTime",
          customRender: formatDate
        },

        {
          title: "操作",
          dataIndex: "id",
          scopedSlots: { customRender: "operation" }
        }
      ],
      problemStatus: problemStatus,
      problemStatusAll: {
        0: "全部",
        BUY: "待您处理",
        ADMIN: "正在为您处理",
        SUCCESS: "已完成",
        CANCEL: "已关闭",
        CLOSE: "已关闭"
      },
      problemStatusDetail: {
        BUY: "待您处理",
        ADMIN: "正在为您处理",
        SUCCESS: "已完成",
        CANCEL: "用户关闭",
        CLOSE: "管理员关闭"
      },
      problemStatusArr: [],
      typeArrayAll: [
        {
          name: "底单申请",
          value: "底单申请",
          id: 1
        },
        {
          name: "快递异常",
          value: "快递异常",

          id: 2
        },
        {
          value: "其他问题",
          name: "其他问题",
          id: 3
        }
      ],
      dataSource: parameter => {
        return problemListAdmin(Object.assign(parameter, this.queryParam)).then(
          res => {
            res.data.list.forEach((item, index) => {
              item.content = JSON.parse(item.content);
            });
            return res.data;
          }
        );
      }
    };
  },
  mounted() {
    this.problemStatusArr = jsonToArray(this.problemStatus);
  },
  beforeDestroy() {
    console.log("清除定时器");
    window.clearInterval(this.time);
  },
  methods: {
    moment,
    formatDate,
    jsonToArray,
    // 时间
    onChangeDatePay(date, dateString) {
      const time = dateString.includes("");
      if (time) {
        delete this.queryParam.createTimeStart;
        delete this.queryParam.createTimeEnd;
      } else {
        this.queryParam.createTimeStart =
          Date.parse(new Date(dateString[0])) - 1000 * 3600 * 8;
        this.queryParam.createTimeEnd =
          Date.parse(new Date(dateString[1])) - 1000 * 3600 * 8;
      }
      this.currentShortcutDate = null;
      this.$refs.table.refresh(false);
    },
    handleShortcutDate(record) {
      this.currentShortcutDate = record.date;

      let createTimeStart;
      let createTimeEnd;
      const today = new Date(new Date().toLocaleDateString()).getTime();
      // 今天
      if (record.date === 0) {
        createTimeStart = today;
        createTimeEnd = today + 24 * 60 * 60 * 1000;
      }
      // 7天内  30天内
      if (record.date === 7 || record.date === 30) {
        createTimeEnd = today + 24 * 60 * 60 * 1000;
        createTimeStart = today - 24 * 60 * 60 * 1000 * (record.date - 1);
      }
      // 昨天
      if (record.date === 1) {
        createTimeStart = today - 24 * 60 * 60 * 1000;
        createTimeEnd = today;
      }
      this.queryParam.createTimeStart = createTimeStart;
      this.queryParam.createTimeEnd = createTimeEnd;
      this.$refs.table.refresh(false);
    },
    resetQueryParam() {
      // 重置查今天
      this.currentShortcutDate = 0;
      // 重置查全部
      this.activeKey = "0";
      this.queryParam = {
        statusList: "BUY,ADMIN,SUCCESS,CANCEL,CLOSE",
        createTimeStart: new Date(new Date().toLocaleDateString()).getTime(),
        createTimeEnd:
          new Date(new Date().toLocaleDateString()).getTime() +
          24 * 60 * 60 * 1000
      };
      this.$refs.table.refresh(false);
    },
    // 切换tab
    changeQueryType(key) {
      this.activeKey = key;
      // 全部
      if (key == "0") {
        this.queryParam.statusList = "BUY,ADMIN,SUCCESS,CANCEL,CLOSE";
      } else if (key == "CANCEL") {
        this.queryParam.statusList = "CANCEL,CLOSE";
      } else {
        this.queryParam.statusList = key;
      }
      this.$refs.table.refresh(false);
    },
    getProblemDetail(no) {
      problemListAdmin({ pageNum: 1, pageSize: 20, no }).then(res => {
        this.detail = JSON.parse(JSON.stringify(res.data.list[0]));
        this.detail.content = JSON.parse(this.detail.content);
        // 刷新全屏加载图片插件
        this.$previewRefresh();
      });
    },
    // 查看详情
    showDetail(record) {
      this.getProblemDetail(record.no);
      this.showDetailData = true;
      // 没结单的定时查详情
      if (
        record.status == "SELL" ||
        record.status == "BUY" ||
        record.status == "ADMIN"
      ) {
        console.log("开始定时查询");
        this.time = setInterval(() => {
          console.log(this.formatDate(new Date().getTime()));
          this.getProblemDetail(record.no);
        }, 10000);
      }
    },

    // 返回列表
    showTableList() {
      this.showDetailData = false;
      console.log("清除定时器");
      window.clearInterval(this.time);
    },

    handleChange(fileData) {
      this.uploadProcessing(fileData.file);
    },

    uploadProcessing(file) {
      filePreUpload()
        .then(response => {
          const data = response.data;
          const url = data.url;
          const prefix = data.key;
          delete data.url;
          delete data.key;
          const array = file.name.replace(" ", "").split("."); // 把文件名数组化
          let suffix = ""; // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`;
          }
          const formData = new FormData();
          Object.keys(data).forEach(it => {
            formData.append(it, data[it]);
          });
          const key = `${prefix}/${uuid()}${suffix}`; // 图片访问路径
          formData.append("key", key);
          formData.append("file", file);
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              const obj = {
                url: "http://czkj-cache.oss-cn-shenzhen.aliyuncs.com/" + key,
                show: false
              };
              this.submit.fileList.push(obj);
              this.$message.success("上传成功");
              // return fileData.onSuccess(); // 上传成功了
            })
            .catch(error => {
              this.$message.error("上传异常." + error);
              // return fileData.onError(); // 拒绝上传操作
            });
        })
        .catch(error => {
          this.$message.error("授权异常." + error);
          // return fileData.onError(); // 拒绝上传操作
        });
    },
    handlePaste(event) {
      if (this.submit.fileList.length >= 5) {
        return this.$message.error("最多只能上传5张图片");
      }
      const items = event.clipboardData && event.clipboardData.items;
      var file = null;
      if (items && items.length) {
        this.pasteText = "";
        // 搜索剪切板items
        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            file = items[i].getAsFile();
            this.uploadProcessing(file);
          }
        }
      } else {
        this.pasteText = "当前浏览器不支持";
        return;
      }
      if (!file) {
        this.pasteText = "粘贴内容非图片";
      }
    },

    handleRemove(item) {
      this.submit.fileList = this.submit.fileList.filter(
        it => it.url !== item.url
      );
    },
    handleMouseEnter(item) {
      item.show = true;
    },
    handleMouseLeave(item) {
      item.show = false;
    },

    handleSubmit() {
      if (!this.submit.input && this.submit.fileList.length < 1) {
        return this.$message.error("请输入沟通内容");
      }
      const files = [];
      this.submit.fileList.forEach(item => {
        files.push(item.url);
      });
      const obj = {
        time: new Date().getTime(),
        admin: true,
        text: this.submit.input,
        files: files
      };
      const contentSubmit = JSON.parse(JSON.stringify(this.detail.content));
      contentSubmit.push(obj);
      const data = {
        id: this.detail.id,
        content: JSON.stringify(contentSubmit)
      };
      problemUpdateAdmin(data).then(res => {
        if (res.success) {
          this.submit.input = "";
          this.submit.fileList = [];
          this.$message.success("提交成功");
          // 定时查询聊天记录
          problemListAdmin({
            pageNum: 1,
            pageSize: 20,
            no: this.detail.no
          }).then(res => {
            res.data.list[0].content = JSON.parse(res.data.list[0].content);
            this.detail = res.data.list[0];
            // 刷新全屏加载图片插件
            this.$previewRefresh();
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    closeProblem(id, status) {
      problemClose({ id, status }).then(res => {
        if (res.success) {
          this.$message.success("操作成功");
          // 详情--结单
          if (status == "CLOSE") {
            problemListAdmin({
              pageNum: 1,
              pageSize: 20,
              no: this.detail.no
            }).then(res => {
              this.detail.status = res.data.list[0].status;
              // 刷新全屏加载图片插件
              this.$previewRefresh();
            });
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async closeProblem2(id, status) {
      const obj = {
        time: new Date().getTime(),
        admin: true,
        text:
          "尊敬的客户，您好，由于您长时间未反馈信息，我们暂时将您的问题改为已结单。若有疑问您可以重新提交工单，谢谢。"
      };
      const contentSubmit = JSON.parse(JSON.stringify(this.detail.content));
      contentSubmit.push(obj);
      const data = {
        id: this.detail.id,
        content: JSON.stringify(contentSubmit)
      };
      problemUpdateAdmin(data).then(res => {
        if (res.success) {
          this.submit.input = "";
          // 关闭订单
          problemClose({ id, status }).then(res => {
            if (res.success) {
              this.$message.success("操作成功");
              // 详情--结单
              if (status == "CLOSE") {
                problemListAdmin({
                  pageNum: 1,
                  pageSize: 20,
                  no: this.detail.no
                }).then(res => {
                  this.detail.status = res.data.list[0].status;
                  // 刷新全屏加载图片插件
                  this.$previewRefresh();
                });
              }
            } else {
              this.$message.error(res.msg);
            }
          });
          // 查询聊天记录
          problemListAdmin({
            pageNum: 1,
            pageSize: 20,
            no: this.detail.no
          }).then(res => {
            res.data.list[0].content = JSON.parse(res.data.list[0].content);
            this.detail = res.data.list[0];
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async getCurDetail(no) {
      try {
        const res = await problemListAdmin({ pageNum: 1, pageSize: 20, no });
        const detail = JSON.parse(JSON.stringify(res.data.list[0]));
        detail.content = JSON.parse(detail.content);
        console.log("[detail] ---> ", detail);
        return detail;
      } catch (error) {
        return null;
      }
    },
    async fastClose(id, status, detail) {
      return new Promise(resolve => {
        const obj = {
          time: new Date().getTime(),
          admin: true,
          text:
            "尊敬的客户，您好，由于您长时间未反馈信息，我们暂时将您的问题改为已结单。若有疑问您可以重新提交工单，谢谢。"
        };
        const contentSubmit = JSON.parse(JSON.stringify(detail.content));
        contentSubmit.push(obj);
        const data = {
          id,
          content: JSON.stringify(contentSubmit)
        };
        problemUpdateAdmin(data).then(res => {
          if (res.success) {
            // 关闭订单
            problemClose({ id, status }).then(res => resolve(res.success));
          } else {
            return resolve(false);
          }
        });
      });
    },
    async batchCloseProblem() {
      if (!this.selectedRowKeys.length) {
        return this.$message.error("请选择要结单的工单");
      }
      this.batchCloseLoading = true;
      let suc = 0;
      for (let index = 0; index < this.selectedRows.length; index++) {
        const detail = this.selectedRows[index];
        const res = await this.fastClose(detail.id, "CLOSE", detail);
        if (res) suc++;
      }
      this.batchCloseLoading = false;
      this.$refs.table.refresh(false);
      this.$message.success(
        `修改成功${suc}条， 失败${this.selectedRowKeys.length - suc}条`
      );
      this.selectedRowKeys.length = 0;
    }
  }
};
</script>

<style lang="less" scoped>
.child_header {
  padding: 24px 40px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
  .time_select {
    span {
      display: inline-block;
      margin-right: 24px;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }
  }
}
.invitetable_wrapper {
  margin-top: 24px;
  padding-bottom: 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
}
.wrapper {
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
}
.detail_wrapper {
  padding: 24px;
  .desc_item {
    margin-bottom: 12px;
  }
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.concat_wrapper {
  padding: 24px 0 24px 24px;

  .concat_list {
    padding-right: 24px;
    .concat_item {
      display: flex;
      padding: 24px 0;
      border-bottom: 1px solid #eee;

      .item_center {
        flex: 1;
        margin-left: 8px;
        &_name {
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          margin-bottom: 4px;
        }
        &_info {
          margin-bottom: 15px;
          word-break: break-all;
        }
        &_img {
          height: 64px;

          margin-right: 8px;
          cursor: pointer;
          border-radius: 4px;
        }
      }
    }
  }
}
.resize_none {
  resize: none;
}
.concat_input {
  margin-bottom: 12px;
}
.concat_upload {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.submit_image {
  width: 104px;
  height: 104px;
  cursor: pointer;
  margin-right: 12px;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.edit_tip {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 104px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: #595959;
}
.paste_box {
  width: 104px;
  height: 104px;
  margin-bottom: 12px;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  .text {
    color: #1890ff;
  }
}
#log {
  color: rgb(249, 50, 68);
}
.action_item {
  margin-bottom: 12px;
}
.submit_btn {
  margin-right: 12px;
}
/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 16px;
  padding: 16px 12px;
}
/deep/ .ant-tabs {
  margin-right: 24px;
}
//滚动条
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #eee;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(216, 213, 213);
}
</style>
